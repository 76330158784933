import React from "react";
import {get} from "lodash";
import {graphql, useStaticQuery} from "gatsby";
import Helmet from "react-helmet";
import Section from "../components/section/section";
import styles from '../scss/page.scss';
import {Accordion as BootstrapAccordion, Card} from "react-bootstrap";
import Img from "gatsby-image/withIEPolyfill";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {authorUrl, buildBreadcrumbsItem, postUrl} from "../helpers";


class EmotionalSupportAnimalLaws extends React.Component {
  constructor(props) {
    super(props);
    this.author = {
      name: 'Prairie Conlon',
      title: 'LPC, NCC'
    }
    this.state = { posSticky: 0 };
    this.contentAccordion = [
      [
        {
          id: 'content-accordion-1',
          title: 'What’s the Difference Between a Service, Emotional Support, and a Therapy Animal?',
          description: 'To understand the differences in the laws, get to know the difference in these animals. Emotional support animals, therapy animals, and service animals can assist people with a disability, but there are differences in how they do so. These three laws can help distinguish them: Americans with Disabilities Act (ADA), Air Carrier Access Act (ACAA), and Fair Housing Act (FHA).'
        },
        {
          id: 'content-accordion-2',
          title: 'What is an Emotional Support Animal (ESA)?',
          description: 'Emotional support animals is an animal who provides comfort and companionship to someone with a psychological, emotional, and/or mental disability. They are usually cats and dogs, but they can be other types of animals too. They don\'t need to be trained to become an ESA.'
        },
        {
          id: 'content-accordion-3',
          title: 'What is the Fair Housing Amendment Act?',
          description: 'The Fair Housing Act was created to protect renters from being discriminated against when applying for housing. For individuals with disabilities, one of the most important factors toward overall wellbeing is a stable place to live. Under the Act, housing providers must provide “reasonable accommodation” for persons with disabilities.'
        },
        {
          id: 'content-accordion-4',
          title: 'Does having an ESA exempt me from pet deposits in housing?',
          description: 'Absolutely! Property owners must waive fees or deposits that would be otherwise required for individuals opting to live with a pet. The Fair Housing Act classifies service animals and ESA’s as assistive aids which means no fees, no ifs, ands or buts.'
        },
        {
          id: 'content-accordion-4',
          title: 'What if an Airline or Landlord does not accept my ESA Letter and charges me a pet-related fee?',
          description: 'When a person with a disability believes that he/she has been subjected to a discriminatory housing practice, including a provider’s wrongful denial of a request for reasonable accommodation, he/she may file a complaint with HUD within one year after the alleged denial or may file a lawsuit in federal district court within two years of the alleged denial. If a complaint is filed, HUD will investigate the complaint at no cost to the person with a disability. If their case goes to an administrative hearing, HUD attorneys will litigate the case on their behalf. The respondent can be ordered to: Compensate the person for actual damages, including humiliation, pain and suffering. Provide injunctive or other equitable relief. Pay the Federal Government a civil penalty to vindicate the public interest. The maximum penalties are $16,000 for a first violation and $70,000 for a third violation within seven years. Pay reasonable attorney’s fees and costs. On the other hand, under the Air Carrier Access Act (ACAA), anyone with a diagnosis of a mental disability and an emotional support animal letter from a mental health professional verifying the emotional benefit of the animal will be allowed to travel with the animal. If the requirements are met, the airline is not legally allowed to ask questions about the disability and cannot restrict ESA owners and their animals from boarding the airplane. In order to be covered by the Air Carrier Access Act and comply with airline regulations, there are certain requirements that ESA owners must meet. These requirements include: Explanation of why the animal provides emotional support. Verification letter from a licensed mental health professional prescribing or endorsing the animal as an ESA offering benefits for a particular mental disability. The mental or emotional diagnosis must comply with the current edition of the Diagnostic and Statistical Manual of Mental Disorders (DSM-V).'
        },
        {
          id: 'content-accordion-5',
          title: 'What’s the Difference Between a Service, Emotional Support, and a Therapy Animal?',
          description: 'To understand the differences in the laws, get to know the difference in these animals. Emotional support animals, therapy animals, and service animals can assist people with a disability, but there are differences in how they do so. These three laws can help distinguish them: Americans with Disabilities Act (ADA), Air Carrier Access Act (ACAA), and Fair Housing Act (FHA).'
        },
        {
          id: 'content-accordion-5',
          title: 'Can I bring my emotional support animal in the cabin of a commercial aircraft?',
          description: 'Yes. Be sure to check with your airline before your travel date. Even though the Air Carrier Access Act regulates procedures, airlines may require additional information and may have other restrictions you should know about.'
        },
        {
          id: 'content-accordion-6',
          title: 'Can I take my emotional support animal into restaurants or other public places?',
          description: 'It depends. Unlike service animals who can enter public places, ESAs can only go into establishments that have a pet-friendly policy. Other than that, they are mainly intended to be a companion during flights and housing.'
        },
        {
          id: 'content-accordion-7',
          title: 'How will my landlord or property manager be able to verify my letter?',
          description: 'A landlord may verify an ESA letter by confirming the therapist’s license number. Since they cannot speak directly to the health care professional because of HIPPA privacy laws, the property manager can request the reasonable accommodation document used by the Department of Housing and Urban Development (HUD) to verify a letter.'
        },
        {
          id: 'content-accordion-8',
          title: 'How do I know that your website is legitimate and not a scam like so many other websites?',
          description: 'The information and documentation we issue is 100% compliant with federal and state laws. Your personalized letter is issued by a real licensed mental health professional in your state. In fact, each letter contains the documentation where the board can be contacted and verified.'
        }
      ],
      [
        {
          id: 'content-accordion-10',
          title: 'ESA Housing Letter',
          description: 'You’ve found the perfect home! You’re ready to move in! But you find out it’s a pet-free building. \n' +
            'Now what? An ESA Housing Letter will prevent you from being denied accommodations in such a residence. Thanks to the Fair Housing Act, those with a Verified ESA are enabled by law to live with their ESA free of charge. But for you take advantage of this law, you must have a diagnosed disability and provide documentation to the property owner or housing representative. Remember that the landlord cannot ask for additional payment because of your ESA, or deny housing even if there is a no pets-policy. Even more, they must respect your privacy and cannot inquire about your disability. However, if your pet is welcomed into this otherwise restricted housing community, it’s important that your pet does not damage property or harm otherwise, or your landlord has the right to evict you.'
        },
        {
          id: 'content-accordion-11',
          title: 'ESA Travel Letter',
          description: 'Flying is stressful, but an ESA can make it much less so. Which is why the Air Carrier Access Act recognizes and acknowledges this need. When you obtain an ESA Letter, you can then fly with your pet, free of charge. Your ESA travel letter, though, needs to be written by a licensed mental health professional and explains that your pet is part of your treatment plan and, through its company, provides you relief from your symptoms. The Air Carrier Access Act protects your rights as an ESA owner and airlines are not allowed to refuse transportation to individuals with an ESA. It’s important to check in with individuals airlines, however, to ascertain whether you will need additional documentation like a Veterinary Health Form, for example. So before you fly, be sure to contact the airlines directly just in case. It’s always better to be prepared so you’re not dealing with unnecessary stress when you arrive at the airport. You can also outfit your emotional support animal with a vest or patch and a photo ID of your pet; but just know that only the letter from your mental health professional and the possible custom forms for each airline is legally required.'
        },
        {
          id: 'content-accordion-12',
          title: 'Legitimate ESA Registration',
          description: 'We can’t emphasize this enough. Emotional Support Animals do not need to be enrolled into a registration. Nor is Emotional Support Animal Certification a real thing, either. We want to protect you from the unethical practices all too common online, so therefore, all you need to be accompanied by an ESA is a letter provided by a licensed mental health professional. The letter should include: The type of license carried by your licensed mental health professional. The license numbers. The issue date of the license. The state in which the license was issued. The date on which the letter was written. The ESA letter should confirm the following information: That you are currently a patient of this LMHP. You are being treated for a disability. That you are significantly inhibited in performing a major life activity. That the prescription of an ESA is an integral part of your treatment plan'
        },
        {
          id: 'content-accordion-11',
          title: 'Is Certapet Legit?',
          description: 'We are a dedicated team determined to teach the world about the very essential and necessary need for an ESA, and CertaPet is the #1 empowerment facilitator of this mental health movement. An ESA Letter from CertaPet provides: Recognition that you are a patient on a case-by-case basis under a licensed mental health professional’s care for mental or emotional disabilities. Verification that you are significantly limited by an individual disability, such as depression or a similar condition, affecting your mental well-being. Prescription for an emotional support dog (also known as a companion dog), or other support animal, as a necessary reinforcement for your mental health within 48 business hour.'
        },
        {
          id: 'content-accordion-12',
          title: 'Is Therapetic Legit?',
          description: 'Working alongside Certapet, Therapetic is the #1 Emotional Support Letter Service. We connect you with a licensed mental health professional in your state to secure you an ESA letter that is compliant with both state and federal regulated.'
        },
        {
          id: 'content-accordion-13',
          title: 'ESA Laws in Your State',
          description: 'If you have an ESA, then you are protected by the FHA and ACAA and afforded travel and housing benefits in all 50 states. So, travel away throughout this great country! However, some states have more restrictions when it comes to emotional support/assistance animals, and it’s important for you to be prepared with knowledge about those discrepancies.'
        },
        {
          id: 'content-accordion-14',
          title: 'Getting an Emotional Support Animal in Indiana',
          description: 'Indiana differs from the other 49 states in a very specific law regarding a patient’s therapy sessions. The law dictates that you need to have additional services, meaning at least one therapy session before you get your ESA letter. To ensure that you comply with this, CertaPet added one therapy session before your ESA consultation and two after.'
        },
        {
          id: 'content-accordion-15',
          title: 'Getting an Emotional Support Animal in Florida',
          description: 'Florida has had some problems in the past and is looking to ensure that those with a real need get the support. Therefore, to reduce the number of people misrepresenting their pets as assistance animals, it is a “misdemeanor of the second degree, punishable as provided in s.108 775.082 or s. 775.083, and must perform 30 hours of community, service for an organization that serves individuals with disabilities, or for another entity or organization at the discretion of the court, to be completed in not more than 6 months” - stated by the Florida Senate.'
        },
        {
          id: 'content-accordion-16',
          title: 'Getting an Emotional Support Animal in Utah',
          description: 'Under state law, a person caught lying to their LMHPs about needing an ESA or misrepresenting a pet as an ESA to landlords or airlines could be charged with a class C misdemeanor. This law is much like the Utah service animal law where it is also a crime to misrepresent a pet as a service dog.'
        },
        {
          id: 'content-accordion-17',
          title: 'Getting an Emotional Support Animal in Tennessee',
          description: 'As you may know, landlords can request an additional reasonable accommodation form in addition to an ESA letter. And now in Tennessee, a tenant can be charged with a Class B misdemeanor if they misrepresent their pet as an ESA or service animal. CertaPet LMHPs are readily able to supply your landlord with the necessary support documentation should they require it.'
        },
        {
          id: 'content-accordion-18',
          title: 'Getting an Emotional Support Animal in New York',
          description: 'Those with certain psychiatric disabilities (anxiety, depression, PTSD, etc.) may be afforded protection for an emotional support animal in New York, even in housing or travel situations that might otherwise prohibit animals. Flying with an emotional support animal in New York requires an ESA travel letter from a licensed mental health professional, which must explain that a mental health disability exists and there is a need for the animal. The letter should clearly indicate the type of practice (ie license type) in which the health care professional specializes. The letter should also be under the health care professional’s letterhead and cannot be more than one year old. Finally, you may be required to give 48 hours advance notice to the carrier. For housing, a landlord may require a renter with an emotional support animal to show documentation (ESA housing letter) supporting the fact that the renter has a disability and that reasonable accommodation for the support animal is necessary.'
        },
        {
          id: 'content-accordion-19',
          title: 'Getting an Emotional Support Animal in California',
          description: 'Like service animals, emotional support animals are protected by California law and federal law in housing and travel. However, support animals do not receive the same protections in public places as service animals do. Landlords are required to allow renters to live with their emotional support animals or service dog in California. This means that a landlord may not evict a renter or even charge a pet deposit for emotional support animals, excluding certain rare exceptions such as harming others or the property. Employers are not legally obligated to allow your ESA in the workplace. It is entirely up to the discretion of the employer whether or not you may bring your ESA to work with you. To fly with an emotional support animal, you will need a letter from a licensed healthcare professional declaring the mental health disability and the need for the animal. The letter must contain the health care professional’s letterhead and must be less than one year old.'
        },
        {
          id: 'content-accordion-20',
          title: 'Getting an Emotional Support Animal in Texas',
          description: 'Under the Texas state law, emotional support animals are companion animals that provide safety and comfort to those suffering from an array of mental illness. Like in many other states, an emotional support animal in Texas will be protected under the Air Carrier Access Act so airlines must make reasonable accommodation to passengers by allowing their comfort animals to join them in the cabin, free of charge. However, as a responsible owner and handler, it is your job to ensure: You fill all the appropriate documents made mandatory by the airline of your choice. That you provide an ESA letter from your licensed mental health professional. Provide a 48-hour notice to the airlines, dictating that you’ll be traveling with an emotional support animal. Your ESA is able to behave well while in a public setting. An emotional support animal in Texas is not protected under the ADA. Therefore, employers do not need to provide accommodation to owners of ESAs. For college students, ESAs are allowed on campus but strictly only permitted in university housing.'
        },
        {
          id: 'content-accordion-21',
          title: 'Getting an Emotional Support Animal in Ohio',
          description: 'An ESA in Ohio is protected by the FHA and ACAA, just as all states are; therefore, you are able to fly and live with your emotional support animal with no added fees. According to Ohio employment law, an ESA is not automatically entitled to attend a workplace, in the way a service animal for a physical disability is. However, when the owner is in possession of an ESA letter from a licensed mental health professional, some employers may be lenient. Many employers work on a case-by-case basis.'
        },
        {
          id: 'content-accordion-22',
          title: 'Emotional Support Animal Training',
          description: 'ESA’s are not service animals and therefore, do not require special training. However, it’s important that your pet understands basic commands like “sit” or “stay,” and behaves appropriately in public. Your support need is important but it’s also imperative that the quality of life of those around you is not disturbed as well. But what happens if there is an issue? An airline can revoke your privilege if you pet acts aggressively toward flight staff or other passengers. And landlords can also deny accommodations or file for an eviction if your pet is disruptive or if your ESA bothers other tenants. Training your pet to handle public environments surrounded by strangers is imperative for your peace of mind in traveling or living with an ESA. We can’t emphasize this enough--your need is valid and essential, but while we work toward educating the masses about ESA, it’s imperative for you and others that we create a positive experience for all.'
        }

      ],
    ];
    this.itemsRefs = {}
    this.contentAccordion.map(( items, index ) => {
      this.itemsRefs[index] = {}
      items.map(( item, i ) => {
        this.itemsRefs[index][i] = React.createRef();
      })
    })
    this.breadcrumbs = [
      buildBreadcrumbsItem('Emotional Support Animal Laws', this.props.location.href)
    ]
    this.toggleHeaderClass = this.toggleHeaderClass.bind(this);
    this.goToElement = this.goToElement.bind(this)
    this.seo = {
      title: 'FAQ and Emotional Support Animals Laws',
      description:  'What is an emotional support animal? Can you take an ESA in public? Are there laws for emotional support dogs? Get the answers to the most FAQs and Laws here.',
      seoTitle: 'FAQ and Emotional Support Animals Laws'
    }
    this.post = {
      slug: 'emotional-support-animal-laws',
    }
  }

  componentDidMount() {
    const targetElementSticky = document.getElementById('sticky-bar')
    const stickyHeight = targetElementSticky.getBoundingClientRect().height
    this.setState(() => {
      return { posSticky: stickyHeight };
    });
  }

  toggleHeaderClass(index, i) {
    if (this.itemsRefs) {
      for (let f in this.itemsRefs) {
        for (let x in this.itemsRefs[f]) {
          if ((index == f && x == i) || this.itemsRefs[f][x].current.classList.contains('open')) {
            this.itemsRefs[f][x].current.classList.toggle('open')
          }
        }
      }
    }
  }

  /**
   * Smoothly scrolls down to element in page
   * @param  {Event} e
   * @return {void}
   */
  goToElement(e) {
    const { currentTarget } = e
    const id = (currentTarget.getAttribute('href') || '').replace('#', '')
    const targetElement = document.getElementById(id)
    if (targetElement) {
      e.preventDefault()
      const pos = targetElement.getBoundingClientRect()
      const targetTop = pos.top + window.scrollY - this.state.posSticky
      window.scroll({top: targetTop, left: 0, behavior: 'smooth' });
    }
  }

  displayAccordion (n) {
    return (
      <BootstrapAccordion key={`accordion-${n}`} className="mt-5">
        {this.contentAccordion[n].map((item, i) => {
          return (
            <Card key={i}>
              <Card.Header ref={ this.itemsRefs[n][i] }>
                <BootstrapAccordion.Toggle as="h3" id={item.id} eventKey={i} onClick={this.toggleHeaderClass.bind(this, n, i)} className="col-12 col-md-6 mx-auto my-3">
                  <div className="row">
                    <div className="accordion-title col-10 col-md-10">{item.title}</div>
                    <div className="col-2 col-md-2">
                      <p className="icon float-md-right float-left"/>
                    </div>
                  </div>
                </BootstrapAccordion.Toggle>
              </Card.Header>
              <BootstrapAccordion.Collapse eventKey={i}>
                <Card.Body>
                  <div className="col-12 col-md-8 float-right mr-md-5">
                    {item.description}
                  </div>
                </Card.Body>
              </BootstrapAccordion.Collapse>
            </Card>
          )

        })}
      </BootstrapAccordion>
    )
  }

  render() {

    const { data, children } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`

    const defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')
    const publisherName = get(this.props, 'data.site.siteMetadata.publisher.name')
    const url = postUrl(this.post, get(this.props, 'data.site.siteMetadata.siteUrl'))

    return (
      <>
        <Layout containerClassNames="page law-and-faqs" breadcrumbs={this.breadcrumbs} location={this.props.location}>
          <SEO { ...this.seo } />
          <article className="post-content-container" itemScope itemType="https://schema.org/BlogPosting">
            <meta itemProp="url" content={ url } />
            <link itemProp="mainEntityOfPage" href={ url } />
            <meta itemProp="datePublished" content="2020-12-12T17:51:29" />
            <meta itemProp="dateModified" content="2020-12-13T17:51:10" />
            <meta itemProp="headline" content="FAQ and Emotional Support Animals Laws" />
            { publisherName ? (
              <link itemProp="publisher" href={ `#${publisherName.replace(' ', '')}Org` } />
            ) : ''}
            { this.author ? (
              <div itemProp="author" itemScope itemType="https://schema.org/Person">
                <meta itemProp="name" content={ this.author.name } />
                <meta itemProp="honorificSuffix" content={ this.author.title } />
              </div>
            ) : '' }
            <div className="post-content" itemProp="articleBody">

              <Section className="page-header-section">
                <div className="row"><h1 className='col-12 col-md-8 mt-5 mx-auto'>Understanding Law’s and FAQ’s</h1></div>
                <div className="row"><div className="blog-data mt-5 mx-auto text-center">
                  Reviewed by: <a href={authorUrl(this.author)} rel="author" className="blog-author">{this.author.name}, {this.author.title}</a>
                </div></div>

              </Section>
              <Section bgColor="#e6f5fa" className="icons-header-section">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto my-lg-n5 mb-lg-auto mt-n5 mb-5">
                    <div className="row">

                      <div className="col-6 col-sm-6 col-lg-6 border item text-center py-4">

                        <Img fixed={data.faqsIcon.childImageSharp.fixed} alt="01-What-is-An-ESA" className="my-auto img-fluid lazyload" />


                        <h2 className="mx-auto mt-md-4">TheraPetic FAQ’s</h2>
                        <br className='d-block d-md-none'/>
                        <br className='d-block d-md-none'/>
                        <div className="row mx-auto">
                          <div className="col-10 col-md-11 btn-learn-more">
                            <a href="#therapetic-faqs" onClick={this.goToElement} className="mx-auto btn-cta">LEARN MORE</a>
                          </div>
                        </div>

                      </div>

                      <div className="col-6 col-sm-6 col-lg-6 border item text-center py-4">

                        <Img fixed={data.lawsIcon.childImageSharp.fixed} alt="02-How-To-Get-One" className="img-fluid lazyload" />


                        <h2 className="mx-auto">Emotional Support Animal Laws</h2>
                        <br className='d-block d-md-none'/>
                        <br className='d-block d-md-none'/>
                        <div className="row mx-auto">
                          <div className="col-10 col-md-11 btn-learn-more">
                            <a href="#emotional-support-animal-laws" onClick={this.goToElement} className="mx-auto btn-cta">LEARN MORE</a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Section>
              <Section bgColor="#fff" className="customize accordion pt-md-5 pb-0 px-0">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto">
                    <Img fluid={data.womanLayingOnFloorWithHerEsa.childImageSharp.fluid} alt="" className="img-fluid lazyload" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-5 mb-1 text-center">
                    <h2 id="therapetic-faqs">TheraPetic FAQ’s</h2>
                    <br/>
                    <div className="call-button my-3 py-3">
                      <a href={`${ctaUrl}&utm_campaign=faq&utm_content=cta1`} target="_blank">See If You Qualify for an ESA Letter</a>
                    </div>
                  </div>
                </div>
                {this.displayAccordion(0)}
              </Section>
              <Section bgColor="#e0f0f0" className="customize bgaccordion pt-md-5 pb-0 px-0">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto pt-md-5">
                    <Img fluid={data.happyGuySittingOnSofaWithHisEmotionalSupportAnimal.childImageSharp.fluid} alt="" className="mt-md-2 img-fluid lazyload" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-5 mb-5">
                    <h2 id="emotional-support-animal-laws">Emotional Support Animal Laws</h2>
                    <br/>
                    <h3>Why Read This Page?</h3>
                    <br/>
                    <p>It’s important that anyone considering an Emotional Support Animal letter familiarize themselves with the laws that apply to owners and what they should expect from airlines and landlords. Yes, it’s a lot of reading, but the more you know, the more prepared you’ll be when you’re confronted by someone who may lack the proper knowledge.</p>
                    <br/>
                    <p>There are two main federal laws applicable to ESA’s and their pet parents; these include the Air Carrier Access Act, and the Fair Housing Act.</p>

                    <br/>
                    <br/>
                      <div className="call-button my-lg-5 text-center">
                        <a href={`${ctaUrl}&utm_campaign=faq&utm_content=cta2`}  target="_blank">See If You Qualify for an ESA Letter</a>
                      </div>
                  </div>
                </div>
                {this.displayAccordion(1)}
              </Section>

            </div>
            <meta itemProp="image" content={ defaultImage.src } />
          </article>
        </Layout>
      </>
    )
  }

}

export default (props) => {
  const data = useStaticQuery(graphql`
    query UnderstandingLaw {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
          siteUrl
          publisher {
            name
          }
        }
      } 
    
      lawsIcon: file(relativePath: { eq: "page-icon/ico-Laws.jpg" }) {
        childImageSharp {
          fixed(width: 102, height: 102) {
            ...GatsbyImageSharpFixed
          }
        }
      } 
      faqsIcon: file(relativePath: { eq: "page-icon/ico-FAQs.jpg" }) {
        childImageSharp {
          fixed(width: 102, height: 102) {
            ...GatsbyImageSharpFixed
          }
        }
      } 
     
      womanLayingOnFloorWithHerEsa: file(relativePath: { eq: "woman-laying-on-floor-with-her-esa.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 778, maxHeight: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      
      happyGuySittingOnSofaWithHisEmotionalSupportAnimal: file(relativePath: { eq: "happy-guy-sitting-on-sofa-with-his-emotional-support-animal.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 778, maxHeight: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      defaultImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    
    }
  `)
  return (
    <EmotionalSupportAnimalLaws  {...props} data={data}/>
  )
};
